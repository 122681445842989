import Modal from "../Modal";
import { compra, compraMobile } from '../../assets/img'
import * as Style from './styles'
import Button from "../Button";
import { useState } from "react";
import { toast } from "react-toastify";

interface modalCuponProps {
  isOpen: boolean
  setCloseModal: (isOpen: boolean) => void
}

export default function ModalCoupon({
  isOpen,
  setCloseModal,
}: modalCuponProps) {
  const [cuponCode] = useState("1COMPRA")

  const handleCopyCupom = () => {
    navigator.clipboard.writeText(cuponCode).then(() => {
      toast.success("Cupom copiado: " + cuponCode)
    }).catch(err =>{
      console.error("Falha ao copiar cupom", err)
    })
  }

  return(
    <Modal title="Cupom - 1º Compra" setOpenModal={setCloseModal} isOpen={isOpen}>
      <Style.ModalContent>
        <Style.ImageBox>
          <picture>
            <source media="(max-width: 1249px)" srcSet={compraMobile}/>
            <Style.Image src={compra} alt="Cupom 1º compra" />
          </picture>
        </Style.ImageBox>
        <Style.ButtonContainer>
          <Style.FirstAccessButton onClick={handleCopyCupom}>
            Copiar cupom
          </Style.FirstAccessButton>
        </Style.ButtonContainer>
      </Style.ModalContent>
    </Modal>
  )
}
